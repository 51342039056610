@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins";
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer components {
  .btn-primary {
    @apply mt-1 mb-2 cursor-pointer rounded-[10px] bg-primaryDark px-[30px] py-[10px] text-[15px] text-white hover:bg-primary disabled:bg-grey;
  }
}

:root {
  /* fallback/default primary color */
  --primary-color: #fad4d8; 
  --secondary-color: #ff49db;
  --primaryDark-color: #c3979f; 
  --secondaryDark-color: #ff7849;
}
